import React, { useState, useEffect } from 'react';
import '../style/hero.scss';

const Hero = () => {
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleButtonClick = () => {
    const accommodationSection = document.getElementById('accommodation');
    if (accommodationSection) {
      accommodationSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
      <section className="hero">
        <div className="hero-image">
          <img src="/assets/herocover.jpg" alt="Hero" />
          <div className="left-section">
            {language === 'SRB' ? (
                <p>Dobrodošli na savršeno mesto <br /> za porodicu</p>
            ) : (
                <p>Welcome to the perfect place <br /> for families</p>
            )}
          </div>
          <div className="right-section">
          </div>
        </div>
      </section>
  );
};

export default Hero;