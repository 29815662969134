import React, { useState, useEffect } from 'react';
import '../style/playroom.scss';

const Playroom = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  // Slideshow images
  const images = [
    "/assets/3.jpg",
    "/assets/35.jpg",
    "/assets/36.jpg",
  ];

  // Function to change slides every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  // Read initial mode from localStorage or default to light mode
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    // Add an event listener for the custom event
    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  // Read initial language from localStorage or default to SRB (Serbian)
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
    setLanguage(savedLanguage);

    // Add an event listener for the custom event
    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section id="playroom" className={`playroom ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <h1 className="playroom-title">{language === 'SRB' ? 'Igraonica za Decu' : 'Playroom for Children'}</h1>
        <div className="playroom-content">
          {/* Slideshow on the left */}
          <div className="playroom-slideshow">
            <img src={images[slideIndex]} alt={`Slideshow - ${slideIndex}`} className="playroom-slide"/>
          </div>

          {/* Text on the right */}
          <p className="playroom-description">
            {language === 'SRB'
                ? 'Naša dečija igraonica je pravi raj za mališane, opremljena raznovrsnim igračkama, ' +
                'edukativnim materijalima i kreativnim aktivnostima koje podstiču maštu i istraživanje. ' +
                'Deca mogu uživati u bezbednom i zabavnom okruženju koje pruža sate igre, smeha i obrazovnih iskustava. ' +
                'Od tobogana i penjalica do interaktivnih zidova i kutaka za crtanje, svako dete će pronaći nešto što ga zanima. ' +
                'Dok se vaša deca igraju i stvaraju divne uspomene, vi se možete savršeno opustiti i uživati u slobodnom vremenu. ' +
                'Naša igraonica je dizajnirana da bude sigurno utočište za decu svih uzrasta, gde se mogu slobodno izraziti i istraživati svoje talente.'
                : 'Our children’s playroom is a true paradise for kids, equipped with a variety of toys, educational materials, and creative activities that stimulate imagination and exploration. Children can enjoy a safe and fun environment that offers hours of play, laughter, and educational experiences. From slides and climbing structures to interactive walls and drawing corners, every child will find something intriguing. While your children play and create wonderful memories, you can perfectly relax and enjoy your free time. Our playroom is designed to be a safe haven for children of all ages, where they can freely express themselves and explore their talents.'}
          </p>
        </div>
      </section>
  );
};

export default Playroom;