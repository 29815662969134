import React, { useState, useEffect } from 'react';
import '../style/wellnesscenter.scss';

const WellnessCenter = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  const images = [
    "/assets/22.jpg",
    "/assets/23.jpg",
    "/assets/24.jpg",
    "/assets/25.jpg",
    "/assets/26.jpg",
    "/assets/27.jpg",
    "/assets/28.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  // Read initial mode from localStorage or default to light mode
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    // Add an event listener for the custom event
    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  // Read initial language from localStorage or default to SRB (Serbian)
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
    setLanguage(savedLanguage);

    // Add an event listener for the custom event
    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB'; // Default language is Serbian
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section className={`wellness-center ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="wellness-container">
          <div className="left-section">
            <div className="title-container">
              <h1 className="main-title">{language === 'SRB' ? 'Spa i Wellness Centar' : 'Spa and Wellness Center'}</h1>
              <span className="arrow">→</span>
            </div>
            <p className="description">
              {language === 'SRB'
                  ? 'Nudimo savršeno mesto za relaksaciju, uz hidromasažnu kadu, saunu, parno kupatilo, tepidarijum i sobe za masaže. ' +
                  'Našim gostima su na raspolaganju profesionalne masaže i širok izbor tretmana tela koristeći visokokvalitetnu kozmetiku za dodatno opuštanje i revitalizaciju. ' +
                  'Obezbedite sebi vrhunsko spa iskustvo u mirnom okruženju Zlatibora, gde se možete osloboditi stresa i napetosti svakodnevnog života.'
                  : 'We offer the perfect place for relaxation, featuring a jacuzzi for 12 people, sauna, steam bath, tepidarium, and two massage rooms. ' +
                  'Guests have access to professional massages and a wide range of body treatments using high-quality cosmetics for additional relaxation and revitalization. ' +
                  'Ensure yourself a top-notch spa experience in the serene environment of Zlatibor, where you can relieve the stress and tension of everyday life. '
              }
            </p>
          </div>
          <div className="right-section">
            <div className="wellslideshow">
              {images.map((src, index) => (
                  <img key={index} src={src} alt={`Slide - ${index}`} className={`slide ${index === slideIndex ? 'active' : ''}`} />
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default WellnessCenter;