import React, { useState, useEffect } from 'react';
import '../style/accomodation.scss';

const Accommodation = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  // Slideshow images
  const images = [
    "/assets/7.jpg",
    "/assets/8.jpg",
    "/assets/9.jpg",
    "/assets/10.jpg",
    "/assets/11.jpg",
    "/assets/12.jpg",
    "/assets/13.jpg",
    "/assets/14.jpg",
    "/assets/15.jpg",
    "/assets/1.jpg",
    "/assets/29.jpg",
    "/assets/30.jpg",
    "/assets/31.jpg",
    "/assets/15.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode); // Update the state
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    const handleDarkModeToggle = () => {
      const newDarkMode = localStorage.getItem('darkMode') === 'true'; // Renamed to avoid conflict
      setIsDarkMode(newDarkMode);
      document.body.classList.toggle('dark-mode', newDarkMode);
      document.body.classList.toggle('light-mode', !newDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section id="accommodation" className={`accommodation ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="accommodation-container">
          <div className="accommodation-left-section">
            <h1 className="accommodation-main-title">
              {language === 'SRB' ? 'SMEŠTAJ' : 'ACCOMMODATION'} <span className="accommodation-arrow">→</span>
            </h1>
            <p className="accommodation-description">
              {language === 'SRB' ?
                  'Naš kompleks raspolaže sa 30 luksuznih smeštajnih jedinica od 40m², osmišljenih da obezbede maksimalan komfor i uživanje.\n' +
                  'Svaki apartman se sastoji od spavaće sobe, prostranog dnevnog boravka sa rasklopivim ležajem, potpuno opremljene kuhinje,\n' +
                  'modernog kupatila i terase. Apartmani su opremljeni TV-om i WIFI internetom velike brzine.\n' +
                  'Svi apartmani imaju obezbeđeno parking mesto.'
                  :
                  'Our complex features 30 luxurious accommodation units of 40m², designed to provide maximum comfort and enjoyment.\n' +
                  'Each apartment consists of a bedroom, a spacious living room with a fold-out bed, a fully equipped kitchen,\n' +
                  'a modern bathroom, and a terrace. The apartments are equipped with a TV and high-speed WIFI internet.\n' +
                  'All apartments have secured parking.'}
            </p>
          </div>

          <div className="accommodation-right-section">
            {/* Slideshow */}
            <div className="accommodation-acomslideshow">
              {images.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow - ${index}`}
                      className={`accommodation-slide ${index === slideIndex ? 'active' : ''}`}
                  />
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default Accommodation;