import React, { useState, useEffect } from 'react';
import '../style/restaurant.scss';

const Restaurant = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [language, setLanguage] = useState('SRB'); // Default language is Serbian

  const images = [
    "/assets/4.jpg",
    "/assets/5.jpg",
    "/assets/16.jpg",
    "/assets/17.jpg",
    "/assets/18.jpg",
    "/assets/19.jpg",
    "/assets/20.jpg",
    "/assets/21.jpg",

  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
    document.body.classList.toggle('light-mode', !savedDarkMode);

    const handleDarkModeToggle = () => {
      const isDarkMode = localStorage.getItem('darkMode') === 'true';
      setIsDarkMode(isDarkMode);
      document.body.classList.toggle('dark-mode', isDarkMode);
      document.body.classList.toggle('light-mode', !isDarkMode);
    };

    window.addEventListener('darkModeToggled', handleDarkModeToggle);

    return () => {
      window.removeEventListener('darkModeToggled', handleDarkModeToggle);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'SRB';
    setLanguage(savedLanguage);

    const handleLanguageChange = () => {
      const currentLanguage = localStorage.getItem('language') || 'SRB';
      setLanguage(currentLanguage);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
      <section id="restaurant" className={`restaurant ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="restaurant-container">
          <div className="restaurant-right-section">
            <div className="restaurant-title-with-restarrow">
              <span className="restaurant-restarrow">&larr;</span>
              <h1 className="restaurant-main-title">{language === 'SRB' ? 'RESTORAN' : 'RESTAURANT'}</h1>
            </div>
            <p className="restaurant-description">
              {language === 'SRB' ?
                  'U srcu našeg kompleksa nalazi se restoran Binis, poznat po jedinstvenoj ponudi vrhunskih jela i porodičnoj atmosferi. ' +
                  'Gosti mogu uživati u tradicionalnim i internacionalnim specijalitetima. ' +
                  'Ono što izdvaja restoran Binis nije samo ukusna hrana, već i prostor koji pruža neizmernu radost deci. Naša igraonica je mesto gde mališani mogu istraživati, ' +
                  'stvarati i uživati, dok roditelji opušteno uživaju u obroku i razgovoru.'
                  :
                  'At the heart of our complex lies Restaurant Binis, known for its unique offering of exquisite dishes and a family-friendly atmosphere. ' +
                  'Guests can enjoy traditional and international specialties. ' +
                  'What sets Restaurant Binis apart is not just the delicious food, but also the space that brings immense joy to children. ' +
                  'Our playroom is a place where young ones can explore, create, and enjoy while parents relax and enjoy their meals and conversations.'}
            </p>
            <div className="restaurant-slideshow">
              {images.map((img, index) => (
                  <img
                      key={index}
                      src={img}
                      alt={`Slideshow ${index}`}
                      className={`restaurant-slide ${index === slideIndex ? 'restaurant-slide-active' : ''}`}
                  />
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default Restaurant;